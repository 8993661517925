var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ec-wrapper',_vm._b({attrs:{"hasSheet":"","hasHeader":"","hasDial":"","hasReload":""},on:{"reload":_vm.loadData},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"padding":"2px 10px"}},[_c('ec-search',{attrs:{"label":"Person suchen"},on:{"suche":function($event){_vm.suche = $event}}})],1)]},proxy:true},{key:"dialogs",fn:function(){return [_c('formular-selector',{ref:"addPerson",attrs:{"name":"addPerson"}})]},proxy:true}])},'ec-wrapper',_vm.config,false),[_c('v-data-table',{attrs:{"headers":[
    {
      text: 'Vorname',
      value: 'vorname'
    },
    {
      text: 'Nachname',
      value: 'nachname'
    },
    {
      text: 'GebDat',
      value: 'gebDat'
    }
  ],"items":_vm.data.filter(_vm.$util.filter(_vm.suche)),"rows-per-page-items":[_vm.rowCount]},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{class:"geschlecht-" + props.item.geschlecht,on:{"click":function($event){return _vm.$router.push({ path: ("/personen/" + (props.item.personID) + "/home"), query: { prev: _vm.$route.fullPath } })}}},[_c('td',[_vm._v(_vm._s(props.item.vorname))]),_c('td',[_vm._v(_vm._s(props.item.nachname))]),_c('td',[_vm._v(_vm._s(props.item.gebDat.split("-").reverse().join(".")))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }