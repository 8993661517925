import { render, staticRenderFns } from "./anmeldungen.route.vue?vue&type=template&id=00f4cbd0&scoped=true&lang=pug&"
import script from "./anmeldungen.route.vue?vue&type=script&lang=ts&"
export * from "./anmeldungen.route.vue?vue&type=script&lang=ts&"
import style0 from "./anmeldungen.route.vue?vue&type=style&index=0&id=00f4cbd0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../.yarn/$$virtual/vue-loader-virtual-0dde8fecb5/0/cache/vue-loader-npm-15.9.6-b3270576fe-30c649d779.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f4cbd0",
  null
  
)

export default component.exports