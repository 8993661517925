var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ec-wrapper',{attrs:{"title":_vm.title}},[_c('v-select',{attrs:{"items":[{text: 'Alles', value: 'all'} ].concat( _vm.ecKreisData.map(function (v,i){ return ({text: v.bezeichnung,value: i}); }))},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}}),_c('v-btn',{on:{"click":_vm.download}},[_vm._v("Download")]),_c('v-data-table',{attrs:{"headers":[
      {
        text: 'Name',
        value: 'nachname'
      },
      {
        text: 'Letzes FZ',
        value: 'datumDesLetztenFZ.input'
      },
      {
        text: 'Letzer FZ-Antrag',
        value: 'fzAntraege[0].erzeugt.input'
      }
    ],"items":_vm.currentData,"rows-per-page-items":[_vm.rowCount]},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{style:(("" + (_vm.state(props.item)))),on:{"click":function($event){return _vm.$router.push({ path: ("/personen/" + (props.item.personID) + "/home"), query: { prev: _vm.$route.fullPath } })}}},[_c('td',[_vm._v(_vm._s(props.item.vorname)+" "+_vm._s(props.item.nachname)+" "+_vm._s(props.item.gebDat.german))]),_c('td',[_vm._v(_vm._s(props.item.datumDesLetztenFZ ? props.item.datumDesLetztenFZ.german : 'N/A'))]),_c('td',[_vm._v(_vm._s([props.item.fzAntraege.reduce(function (acc, c) { return acc === null ? c : (acc.erzeugt.input > c.erzeugt.input ? acc : c); }, null)].map(function (v){ return v === null ? "N/A" : ((v.erzeugt.german) + " - " + (v.erzeugt_durch)); })[0]))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }