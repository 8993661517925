














import { Component, Vue, Mixins } from 'vue-property-decorator';
import abstractField from '../abstract';

// // @ts-ignore
// import { VForm, VStepper, VStepperStep, VBtn } from 'vuetify/lib';

@Component({
  // components: {
  //   VForm,
  //   VStepper,
  //   VStepperStep,
  //   VBtn,
  // },
})
export default class FormStepper extends Mixins(abstractField) {
  public currStep: number = 0;
  public valid: any = {};

  public error: any = {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false
  };

  public clickBtn(cb: undefined|((currStep: number, valid: boolean, cancel: () => void, save: () => void, self: this, set: any ) => void | number) ) {
    // await this.$validator.validateAll()
    if (cb) {
      const val = cb(this.currStep, this.valid[this.currStep], this.cancel, this.save, this, Vue.set);
      if (val) {
        this.currStep = val;
      }
    }
  }
}
