import personBase from './personBase';
export default {
  title: 'Person editieren',
  initval: {
    vorname: '',
    nachname: '',
    gebDat: '',
    geschlecht: ''
  },
  schema: personBase
};
