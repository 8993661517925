
















import { Component, Vue, Mixins } from 'vue-property-decorator';
import abstractField from '../abstract';
// // @ts-ignore
// import { VCheckbox } from 'vuetify/lib';

@Component({
  // components: {
  //   VCheckbox,
  // },
})
export default class FormCheckbox extends Mixins(abstractField) {}
