




import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({})
export default class EcRootIndexAnmeldungenIdIndexFinanzen extends Vue {
  public static meta = {};

  @Prop({default: {person: {}}})
  private data!: any;
}
