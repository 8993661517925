































































































































































import { Component, Vue } from 'vue-property-decorator';
import pack from '@/plugins/package';
import gql from 'graphql-tag';
import * as save from 'js-cookie';
import { subscribe } from '../plugins/sw';
// ()
@Component({})
export default class EcRootIndex extends Vue {
  private get breadcrumbsRouter(): any[] {
    return this.breadMap(
      this.$route.path
        .split('/')
        .slice(1)
        .map((v) => v[0].toUpperCase() + v.slice(1))
    );
  }
  public static meta = {};
  private password = '';
  private dark = save.get('dark') === 'x';
  private drawer = null;
  private version = pack.version || 'Fehler';
  private breadcrumbs = this.breadMap([
    `© 2017 - ${new Date().getFullYear()}`,
    'EC-Nordbund',
    'T. Krause + S. Krüger'
  ]);
  private loginDialog = false;
  private subscribe = subscribe;

  private data: any = {
    person: { vorname: {}, nachname: {} },
    ablaufDatum: {}
  };

  private loading = false;

  private alive: number = -1;

  private timer: null | NodeJS.Timeout = null;
  private isCapsOn = false;
  private valid = false;
  private showPasword = false;

  public logIn() {
    this.loading = true;
    this.$apolloClient
      .mutate({
        mutation: gql`
          mutation($username: String!, $password: String!) {
            logIn(version: "3.0.0", username: $username, password: $password)
          }
        `,
        variables: {
          password: this.password,
          username: localStorage.getItem('username')
        }
      })
      .then(async (res: any) => {
        // let path = this.$route.query.next || '/home';
        // if (this.$route.query.next === '/404?prev=%2F') {
        //   path = 'home';
        // }
        // save.set('username', this.data.username, { expires: 7 });
        // localStorage.setItem('username', this.data.username);
        await this.$setAuthToken(res.data.logIn);
        // this.$router.push(path as string);
        this.loading = false;
        this.updateAlive();
        this.password = '';
        this.loginDialog = false;
      })
      .catch((err: any) => {
        this.$dialog.error({
          text: err.message,
          title: 'Anmelden fehlgeschlagen!'
        });
        this.loading = false;
      });
  }

  public checkCaps(ev: KeyboardEvent) {
    const key = ev.key;
    if (key.length === 1) {
      this.isCapsOn =
        key.toUpperCase() === key && key.toLowerCase() !== key && !ev.shiftKey;
    } else {
      if (key === 'CapsLock') {
        this.isCapsOn = !this.isCapsOn;
      }
    }
  }

  private logout() {
    this.$logout();
  }

  private toggleDark() {
    this.dark = !this.dark;
    save.set('dark', this.dark ? 'x' : '');
  }

  private breadMap(arr: string[]) {
    return arr.map((el) => ({ text: el, disabled: true }));
  }
  private created() {
    if (!this.$authToken()) {
      this.$router.push({
        path: '/login',
        query: { next: this.$route.fullPath }
      });
    } else {
      this.$apolloClient
        .query({
          query: gql`
            query($authToken: String!) {
              getMyUserData(authToken: $authToken) {
                userName
                person {
                  vorname
                  nachname
                }
                ablaufDatum {
                  german
                }
              }
            }
          `,
          variables: {
            authToken: this.$authToken()
          }
        })
        .then((res) => {
          this.data = res.data.getMyUserData;
        });
    }

    this.timer = setInterval(this.updateAlive, 30 * 1000);
    this.updateAlive();
  }

  private async updateAlive() {
    const num = await this.$logoutIn();

    const mins = Math.trunc(num / 1000 / 60);

    this.alive = mins;

    if (this.alive < 15) {
      this.loginDialog = true;
    }
  }

  private beforeDestroy() {
    if (!this.timer) { return; }
    clearInterval(this.timer);
  }
}
