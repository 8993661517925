






















import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({})
export default class EcLesezeichenShow extends Vue {
  public liste = {};

  public created() {
    this.liste = this.$util.lesezeichen.lesezeichen;
  }
}
