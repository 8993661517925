





























































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import copy from 'copy-to-clipboard';

@Component({})
export default class EcRootIndexAnmeldungenIdIndexHome extends Vue {
  public static meta = {};

  private copy = copy

  private rollen = [
    'Teilnehmer',
    'Mitarbeiter',
    'Küchenmitarbeiter',
    'Küchenleitung',
    'Leitung',
    'Hauptleitung'
  ];

  @Prop({default: {person: {}}})
  private data!: any;
}
