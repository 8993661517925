













import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

// // @ts-ignore
// import { VToolbar, VSpacer, VBtn, VIcon, VDialog, VApp, VCard, VCardTitle, VCardText, VCardActions } from 'vuetify/lib';

@Component({
  // components: {
  //   VSpacer,
  //   VBtn,
  //   VDialog,
  //   VToolbar,
  //   VApp,
  //   VCard,
  //   VCardTitle,
  //   VCardText,
  //   VCardActions,
  // },
})
export default class EcRootIndexAKIndex extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private schema!: any;

  @Prop()
  private initval!: any;

  private valid = false;
  private visible = false;
  private value: any = {};

  @Watch('initVal', {immediate: true})
  public onInitValChange() {
    this.value = this.initval;
  }

  private save() {
    this.$emit('save', this.value);
  }
}
