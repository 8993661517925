var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_c('h1',{directives:[{name:"font",rawName:"v-font"},{name:"primary",rawName:"v-primary"}]},[_vm._v("Person abmelden")])]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('formular',{attrs:{"schema":[
          {
            name: 'adresse',
            type: 'autocomplete',
            label: 'Adresse',
            rule: "required",
            required: true,
            items: _vm.myData.adressen.filter(function (v){ return !v.isOld; }).map(function (a) {
              return {
                text: a.strasse + " | " + a.plz + " " + a.ort,
                value: a.adressID
              }
            })
          },
          {
            name: 'email',
            type: 'autocomplete',
            label: 'E-Mail',
            rule: "required",
            required: true,
            items: _vm.myData.emails.filter(function (v){ return !v.isOld; }).map(function (a) {
              return {
                text: a.eMail,
                value: a.eMailID
              }
            })
          },
          {
            name: 'telefon',
            type: 'autocomplete',
            label: 'Telefon',
            rule: "required",
            required: true,
            items: _vm.myData.telefone.filter(function (v){ return !v.isOld; }).map(function (a) {
              return {
                text: a.telefon,
                value: a.telefonID
              }
            })
          }
        ]},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"flat":""},on:{"click":function($event){_vm.visible=false}}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":_vm.abmeldenSave}},[_vm._v("Speichern")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }