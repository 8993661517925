var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"400px","persistend":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_c('h1',{directives:[{name:"font",rawName:"v-font"},{name:"primary",rawName:"v-primary"}]},[_vm._v(_vm._s(_vm.type==='add' ? 'Neues Mitglied hinzufügen' : 'Mitglied bearbeiten'))])]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('formular',{attrs:{"schema":[
          {
            name: 'personID',
            type: 'autocomplete',
            rule: 'required',
            'prepend-icon': 'person',
            items: (_vm.type==='add'?_vm.allPersonen:((_vm.data.personen||[]).filter(function (v){ return (v.currentStatus>0); }).map(function (v){ return v.person; }))).map(function (pers){ return ({value: pers.personID, text: pers.vorname + ' ' + pers.nachname + ' (' + pers.gebDat.german + ')'}); }),
            label: 'Person'
          },
          {
            name: 'status',
            rule: 'required',
            type: 'select',
            items: _vm.stadien.map(function (besch, id){ return ({value: id, text: besch}); }),
            label: 'Neuer Status'
          },
          {
            name: 'date',
            type: 'date',
            label: 'Datum des Updates',
            rule: 'required',
            required: true
          }
        ]},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"flat":""},on:{"click":function($event){_vm.visible=false}}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":_vm.addPersonSave}},[_vm._v("Speichern")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }