





















































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({})
export default class EcNAME extends Vue {
  public static meta = {};
  @Prop()
  private data!: any;

  private rollen = [
    'Teilnehmer',
    'Mitarbeiter',
    'Küchenmitarbeiter',
    'Küchenleitung',
    'Leitung',
    'Hauptleitung'
  ];
  
  subtractYears(numOfYears: number, dateStr: string) {
    const date = new Date(dateStr)
    date.setFullYear(date.getFullYear() - numOfYears);

    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  }

  public getTitle(wplatz: number) {
    if (wplatz === 0) {
      return 'Angemeldet';
    } else if (wplatz === -1) {
      return 'Abgemeldet';
    } else {
      return `Auf Wartelistenplatz ${wplatz}`;
    }
  }
}
