





































import { Component, Vue, Mixins } from 'vue-property-decorator';
import abstractField from '../abstract';
// // @ts-ignore
// import { VAutocomplete, VTextField } from 'vuetify/lib';

@Component({
  // components: {
  //   VAutocomplete,
  //   VTextField,
  // },
})
export default class FormInput extends Mixins(abstractField) {
  public map: any = (window as any).$plz;
  public plz = Object.keys((window as any).$plz);

  public plzChange($event: string) {
    if (this.map[$event].length === 1) {
      this.changeValue({ort: this.map[$event][0], plz: $event, strasse: this.value.strasse});
    } else {
      this.changeValue({ort: undefined, plz: $event, strasse: this.value.strasse});
    }
  }
}
