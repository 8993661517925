












import { Component, Vue, Mixins } from 'vue-property-decorator';
import abstractField from '../abstract';
// // @ts-ignore
// import { VAutocomplete } from 'vuetify/lib';

@Component({
  // components: {
  //   VAutocomplete,
  // },
})
export default class FormInput extends Mixins(abstractField) {}
