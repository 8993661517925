




import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({})
export default class EcRootIndexVeranstaltungsortIdIndexVeranstaltungen extends Vue {
  public static meta = {};
}
