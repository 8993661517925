import { render, staticRenderFns } from "./_.route.vue?vue&type=template&id=2209ec45&"
import script from "./_.route.vue?vue&type=script&lang=ts&"
export * from "./_.route.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../.yarn/$$virtual/vue-loader-virtual-0dde8fecb5/0/cache/vue-loader-npm-15.9.6-b3270576fe-30c649d779.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports