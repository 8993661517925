






































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({})
export default class EcNAME extends Vue {
  public static meta = {};

  @Prop({ default: { person: {} } })
  private data!: any;

  private stadien = ['Ausgetreten', 'Mitglied', 'Vertreter', 'Leiter'];

  public showAll(value: string) {
    this.$dialog.notify.info(value);
  }
}
