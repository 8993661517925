










import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({})
export default class EcRootIndex404 extends Vue {
  public static meta = {};
}
