




























import { Component, Vue, Prop } from 'vue-property-decorator';
import gql from 'graphql-tag';
@Component({})
export default class EcRootIndexAnmeldungenIndex extends Vue {
  public static meta = {};

  public rowCount = 0;

  private data: any = [];

  private suche = '';


  private config = {
    sheet: [
      {
        id: 'anmel_add',
        icon: 'assignment_ind',
        label: 'Anmeldung eingeben',
        click: this.sheetClick
      }
    ],
    title: 'Anmeldungen'
  };

  private loadData() {
    this.$apolloClient.query({
      query: gql`
        query($authToken:String!) {
          anmeldungen(authToken:$authToken) {
            anmeldeID
            person {
              personID
              vorname
              nachname
              gebDat {
                german
              }
            }
            veranstaltung {
              veranstaltungsID
              bezeichnung
              begin {
                german
              }
              ende {
                german
              }
            }
          }
        }
      `,
      variables: {
        authToken: this.$authToken()
      },
      fetchPolicy: 'no-cache'
    }).then((res: any) => {
      this.data = res.data.anmeldungen;
    }).catch((err: any) => {
      this.$dialog.error({
        text: err.message,
        title: 'Laden fehlgeschlagen!'
      });
    });
  }

  private sheetClick(item: {id: string}) {alert(item.id); }

  private created() {
    this.loadData();
    this.getCount();
  }

  private getCount() {
    const tableHeight = window.innerHeight - 64 - 80 - 72 - 32 - 56 - 36 - 50 - 5;
    this.rowCount = Math.floor(tableHeight / 50);
  }
}
