













import { Component, Vue, Prop } from 'vue-property-decorator';
import gql from 'graphql-tag';

@Component({})
export default class EcRootIndex extends Vue {
  public static meta = {};
  private config = {
    sheet: [
      {
        id: 'orga_add',
        icon: 'add',
        label: 'Organisation hinzufügen',
        click: this.sheetClick
      }
    ],
    title: 'Organisationen'
  };

  private suche = '';
  private data: any = [];

  private sheetClick(item: {id: string}) {alert(item.id); }

  private loadData() {
    this.$apolloClient.query({
      query: gql`
        query($authToken: String!) {
          orgas(authToken: $authToken) {
            organisationsID
            bezeichnung
            plz
            ort
            land
          }
        }
      `,
      variables: {
        authToken: this.$authToken()
      }
    }).then((res: any) => {
      this.data = res.data.orgas;
    }).catch((err: any) => {
      this.$dialog.error({
        text: err.message,
        title: 'Laden fehlgeschlagen!'
      });
    });
  }


  private filterData(item: any): boolean {
    return this.suche
      .toLowerCase()
      .split(' ')
      .map((suche: string) => this.filterPart(item, suche))
      .reduce((a, b) => a && b, true);
  }

  private filterPart(item: any, suche: string): boolean {
    if (!suche) {
      return true;
    }
    if (typeof item === 'string') {
      return item.toLowerCase().includes(suche);
    } else if (typeof item === 'number' || typeof item === 'boolean') {
      return item.toString().toLowerCase().includes(suche);
    } else if (item) {
      return Object.keys(item).map((key) => this.filterPart(item[key], suche)).reduce((a, b) => a || b, false);
    } else {
      return false;
    }
  }

  private created() {
    this.loadData();
  }
}
