

















































import { Component, Vue } from 'vue-property-decorator';
import { errorHandler } from '../helpers';
// import * as settings from 'electron-settings'
import gql from 'graphql-tag';
import * as save from 'js-cookie';

@Component({})
export default class EcRootLogin extends Vue {
  private dark = save.get('dark') === 'x';
  private isCapsOn = false;
  private valid = false;
  private loading = false;
  private data = {
    username: (save.get('username') as string) || '',
    password: ''
  };
  private showPasword = false;

  public checkCaps(ev: KeyboardEvent) {
    const key = ev.key;
    if (key.length === 1) {
      this.isCapsOn =
        key.toUpperCase() === key && key.toLowerCase() !== key && !ev.shiftKey;
    } else {
      if (key === 'CapsLock') {
        this.isCapsOn = !this.isCapsOn;
      }
    }
  }

  public logIn() {
    this.loading = true;

    fetch('https://api.ec-nordbund.de/v6/login', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        version: '3.2.0',
        ...this.data
      })
    })
      .then(errorHandler)
      .then((res) => res.json())
      .then(async (res: any) => {
        let path = this.$route.query.next || '/home';
        if (this.$route.query.next === '/404?prev=%2F') {
          path = 'home';
        }
        save.set('username', this.data.username, { expires: 7 });
        localStorage.setItem('username', this.data.username);
        await this.$setAuthToken(res.authToken);
        this.$router.push(path as string);
        this.loading = false;
      })
      .catch((err: any) => {
        this.$dialog.error({
          text: err.message,
          title: 'Anmelden fehlgeschlagen!'
        });
        this.loading = false;
      });
  }

  public created() {
    window.addEventListener('keyup', this.checkCaps);
    if (this.$authToken()) {
      let path = this.$route.query.next || '/home';
      if (this.$route.query.next === '/404?prev=%2F') {
        path = 'home';
      }
      this.$router.push(path as string);
    }
  }
  public destroyed() {
    window.removeEventListener('keyup', this.checkCaps);
  }
}
