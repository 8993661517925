




import { Component, Vue, Prop } from 'vue-property-decorator';
import {CreateElement} from 'vue';

@Component({})
export default class FormRoot extends Vue {
  @Prop()
  public value!: any;

  @Prop()
  public schema!: any;

  @Prop()
  public tag!: any;

  @Prop({
    required: true
  })
  public cancel!: any;

  @Prop({
    required: true
  })
  public save!: any;
}
