export const name = 'settings';

export default {
  set(valueName: string, value: string) {
    // TODO
  },
  get(valueName: string, defaultValue: string) {
    // TODO
  }
};
