var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ec-wrapper',_vm._b({attrs:{"hasSheet":"","hasHeader":"","hasDial":"","hasReload":""},on:{"reload":_vm.loadData},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"padding":"2px 10px"}},[_c('ec-search',{attrs:{"label":"Anmeldung suchen"},on:{"suche":function($event){_vm.suche = $event}}})],1)]},proxy:true}])},'ec-wrapper',_vm.config,false),[_c('v-data-table',{attrs:{"headers":[
    {
      text: 'AnmeldeID',
      value: 'anmeldeID',
      width: '75px'
    },
    {
      text: 'Person',
      value: 'person.personID',
      width: '45%'
    },
    {
      text: 'Veranstaltung',
      value: 'veranstaltung.veranstaltungsID',
      width: '45%'
    }
  ],"items":_vm.data.filter(_vm.$util.filter(_vm.suche)),"rows-per-page-items":[_vm.rowCount]},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{on:{"click":function($event){return _vm.$router.push({path: ("/anmeldungen/" + (props.item.anmeldeID) + "/home"), query: {prev: _vm.$route.fullPath}})}}},[_c('td',[_vm._v(_vm._s(props.item.anmeldeID))]),_c('td',[_vm._v(_vm._s(props.item.person.vorname)+" "+_vm._s(props.item.person.nachname)+" ("+_vm._s(props.item.person.gebDat.german)+")")]),_c('td',[_vm._v(_vm._s(props.item.veranstaltung.bezeichnung)+" ("+_vm._s(props.item.veranstaltung.begin.german)+" - "+_vm._s(props.item.veranstaltung.ende.german)+")")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }