







































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import abstractField from '../abstract';

// // @ts-ignore
// import { VSpacer, VBtn, VDialog, VTimePicker, VTextField } from 'vuetify/lib';

@Component({
  // components: {
  //   VSpacer,
  //   VBtn,
  //   VDialog,
  //   VTimePicker,
  //   VTextField,
  // },
})
export default class FormInput extends Mixins(abstractField) {
  public modal = false;
  public date = '';

  @Watch('value')
  public onValueChange() {
    this.date = this.value;
  }

  @Watch('date')
  public onDateChange( ) {
    this.changeValue(this.date);
  }
}
