









































import { Component, Vue, Prop } from 'vue-property-decorator';
import gql from 'graphql-tag';

@Component({})
export default class EcRootIndex extends Vue {
  public static meta = {};

  public suche = '';

  public rowCount = 0;
  private data: any = [];

  private config = {
    sheet: [
      {
        id: 'pers_add',
        icon: 'person_add',
        label: 'Person hinzufügen',
        click: () => {
          const self = this;
          (this.$refs.addPerson as any)
            .show()
            .then(
              (data: {
                vorname: string;
                nachname: string;
                gebDat: string;
                geschlecht: string;
              }) => {
                this.$apolloClient
                  .mutate({
                    mutation: gql`
                      mutation(
                        $vorname: String!
                        $nachname: String!
                        $gebDat: String!
                        $geschlecht: String!
                        $authToken: String!
                      ) {
                        addPerson(
                          vorname: $vorname
                          nachname: $nachname
                          gebDat: $gebDat
                          geschlecht: $geschlecht
                          authToken: $authToken
                        )
                      }
                    `,
                    variables: {
                      ...data,
                      anmeldeID: this.$route.params.id,
                      authToken: this.$authToken()
                    }
                  })
                  .then((res: any) => {
                    this.$notifikation(
                      'Neue Person',
                      `Du hast erfolgreich eine neue Person angelegt`
                    );
                    self.$router.push({
                      path: `/personen/${res.data.addPerson}/home`,
                      query: { prev: this.$route.fullPath }
                    });
                  })
                  .catch((err: any) => {
                    this.$dialog.error({
                      text: err.message,
                      title: 'Speichern fehlgeschlagen!'
                    });
                  });
              }
            )
            .catch(this.$empty);
        }
      }
    ],
    title: 'Personen',
    subTitle: 'Liste'
  };

  private loadData() {
    fetch('https://api.ec-nordbund.de/v6/personen', {
      headers: { authorization: this.$authToken() }
    })
      .then((res) => res.json())
      .then((data: any) => {
        this.data = data.personen;
      })
      .catch((err: any) => {
        this.$dialog.error({
          text: err.message,
          title: 'Laden fehlgeschlagen!'
        });
      });
  }

  private created() {
    this.loadData();
    this.getCount();
  }

  private getCount() {
    const tableHeight =
      window.innerHeight - 64 - 80 - 72 - 32 - 56 - 36 - 50 - 5;
    this.rowCount = Math.floor(tableHeight / 50);
  }
}
